import { Link } from "react-router-dom";
import fotoAboutMe from "../assets/fotoAboutMe.webp";

function CopyAboutMe() {
  return (
    <section id="about-me-container">
      <div className="image-container">
        <img
          src={fotoAboutMe}
          alt="fotografia de Carla Mello"
          className="photo-about-me"
          loading="lazy"
        />
      </div>
      <div className="about-me-copy">
        <p>Quem sou eu?</p>
        <p>
          Sou uma pessoa apaixonada pelo que faz e profundamente conectada com o
          desenvolvimento das pessoas. Com mais de 12 anos de experiência na
          vida acadêmica, me tornei professora, escritora e revisora de textos,
          mas, ao longo da minha jornada, percebi que meu verdadeiro propósito
          estava além da técnica de escrita.
        </p>
        <p>
          Após finalizar meu doutorado em Literatura, embarquei em uma nova
          trajetória, onde me aprofundei em cursos de{" "}
          <strong>terapias holísticas</strong> (como
          <strong>
            {" "}
            Reiki Usui, Psicoterapia Holística, Karuna, Ho'oponopono e Reiki
            xamânico
          </strong>
          ) e me encantei com o poder da <strong>cura energética</strong> e do
          equilíbrio emocional. Simultaneamente, iniciei minha prática com{" "}
          <strong>Comunicação Não Violenta (CNV) e Educação Emocional</strong>,
          acreditando que é possível transformar a vida das pessoas de dentro
          para fora, ressignificando emoções e experiências.
        </p>
        <p>
          Foi com essa base que criei uma abordagem integrativa, unindo
          <strong>conhecimentos acadêmicos e terapias holísticas</strong>, com o
          objetivo de apoiar outras pessoas no caminho de{" "}
          <strong>desbloqueio emocional e autoconhecimento</strong>. Mais do que
          apenas ensinar ou revisar textos, me tornei uma facilitadora da
          jornada emocional e transformadora que todos precisamos para viver com
          mais <strong>autonomia, claridade e bem-estar</strong>.
        </p>
        <p>
          Com isso, ofereço serviços que vão além do técnico e do estrutural.
          Como mediadora no processo de <strong>desenvolvimento da inteligência
          emocional</strong>, estou aqui para caminhar ao seu lado, ajudando você a
          superar bloqueios e criar uma vida mais equilibrada, integrando mente,
          corpo e espírito.
        </p>
        <p>
          Saiba sobre a minha história com o reiki{" "}
          <Link
            to="https://carlaholistica.wordpress.com/2022/01/01/minha-historia-com-o-reiki/"
            className="link-reiki"
            target="_blank"
            rel="noopener noreferrer"
          >
            clicando aqui
          </Link>
          .
        </p>
        <p>
          Link para acessar meu{" "}
          <Link
            to="http://lattes.cnpq.br/7381253082104512"
            className="link-lattes"
            target="_blank"
            rel="noopener noreferrer"
          >
            Currículo Lattes
          </Link>
          .
        </p>
      </div>
    </section>
  );
}

export default CopyAboutMe;
