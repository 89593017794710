import { useEffect, useState } from "react";
import PopUpMessage from "../../components/_popUpMessage";
import HeroPages from "../../components/_heroPages";
// import PagesCopy from "../../components/_pagesCopy";
import MentoringServices from "../../components/_mentoringServices";
// import ConsultingServices from "../../components/_consultingServices";
// import EmotionalConsulting from "../../components/_consultingEmotional";
// import HowItWorks from "../../components/_howItWorks";
import CTA from "../../components/_cta";
import FreeContents from "../../components/_freeContents";
import ModalForm from "../../components/_modalForm";

function Coaching() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assunto, setAssunto] = useState('');

  const openModalWithSubject = (subject) => {
    setAssunto(subject);
    setIsModalOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 750);

    return () => clearTimeout(timer);
  }, []);

  const handleOpenModal = () => {
    openModalWithSubject('Serviços terapêuticos');
  };

  return (
    <div className={`content ${isLoaded ? "loaded" : ""}`}>
      <PopUpMessage />
      <HeroPages
        title="Serviços terapêuticos"
        description="A partir de um olhar holístico, aqui você encontra serviços de consultorias individuais, cursos e terapias que contribuem para o seu bem-estar físico, emocional, mental e espiritual."
      />
      {/* <PagesCopy
        copy1="Não consegue escrever seu TCC, dissertação ou tese?"
        copy2="Não tem a mínima ideia de por onde começar a escrever? Travou total ou teve problemas emocionais durante a escrita? Encontra-se sem motivação para se organizar na vida estudantil? Veja abaixo qual opção você precisa para agora."
      /> */}
      <MentoringServices />
      {/* <ConsultingServices />
      <EmotionalConsulting />
      <HowItWorks /> */}
      <CTA
        text="Quer saber mais sobre os diferentes tipos de "
        spanText="serviços terapêuticos?"
        buttonText="Envie uma mensagem"
        onButtonClick={handleOpenModal}
      />
      <FreeContents />
      <ModalForm 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        assuntoInicial={assunto}
      />
    </div>
  );
}

export default Coaching;
