function ServiceCards({ title, description, link, list }) {
  return (
    <div className="service-card">
      <h3>{title}</h3>
      <p>{description}</p>
      {Array.isArray(list) && list.length > 0 && (
        <ul className="service-list">
          {list.map((item, index) => (
            <li key={index} className="service-list-item">
              <p>{item}</p> {/* Exibe cada item da lista diretamente */}
            </li>
          ))}
        </ul>
      )}
      {link && (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Inscreva-se agora
        </a>
      )}
    </div>
  );
}

export default ServiceCards;
