import { Link } from "react-router-dom";

import mentoriaIcon from "../assets/mentoria-icon.png";
import ebookIcon from "../assets/escrita-icon.png";
import coursesIcon from "../assets/cursos-icon.png";

function Services() {
  return (
    <div className="services-section">
      <div className="services-container">
        <div className="service-copy">
          <img
            src={mentoriaIcon}
            alt="Serviços terapêuticos"
            className="service-icon"
          />
          <h3 className="service-title">Serviços terapêuticos</h3>
          <p>
            Atendimentos individuais ou em grupo para desenvolvimento emocional,
            equilíbrio e bem-estar, integrando inteligência emocional,
            intelectual e espiritual.
          </p>
          <Link
            to="/servicos-terapeuticos"
            className="more-info"
          >
            Quero saber mais
          </Link>
        </div>
        <div className="service-copy">
          <img
            src={coursesIcon}
            alt="Serviços educacionais"
            className="service-icon"
          />
          <h3 className="service-title">Serviços educacionais</h3>
          <p>
            Cursos, mentorias e consultorias para aplicar a educação emocional
            na vida acadêmica e no desenvolvimento pessoal de forma prática e
            consciente.
          </p>
          <Link
            to="/servicos-educacionais"
            className="more-info"
          >
            Quero saber mais
          </Link>
        </div>
        <div className="service-copy">
          <img
            src={ebookIcon}
            alt="ebooks"
            className="service-icon"
          />
          <h3 className="service-title">Ebooks</h3>
          <p>
            Materiais exclusivos das áreas de conhecimento acadêmico, terapias
            holísticas e educação emocional para sua jornada de
            autoconhecimento e aprendizado contínuo.
          </p>
          <Link
            to="/ebooks"
            className="more-info"
          >
            Quero saber mais
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Services;
